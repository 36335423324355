import React from 'react'

const Humidity = () => {
  return (
    <svg
      width='204'
      height='107'
      viewBox='0 0 204 107'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='15.4694'
        height='42.8265'
        rx='7.7347'
        transform='matrix(0.966176 0.257884 -0.259757 0.965674 11.1245 61.6542)'
        fill='url(#paint0_linear_1_160)'
      />
      <rect
        width='15.4694'
        height='42.8265'
        rx='7.7347'
        transform='matrix(0.966176 0.257884 -0.259757 0.965674 61.2649 61.6542)'
        fill='url(#paint1_linear_1_160)'
      />
      <rect
        width='15.4694'
        height='42.8265'
        rx='7.7347'
        transform='matrix(0.966176 0.257884 -0.259757 0.965674 111.405 61.6542)'
        fill='url(#paint2_linear_1_160)'
      />
      <rect
        width='15.4694'
        height='42.8265'
        rx='7.7347'
        transform='matrix(0.966176 0.257884 -0.259757 0.965674 161.546 61.6542)'
        fill='url(#paint3_linear_1_160)'
      />
      <rect
        width='15.4694'
        height='42.8265'
        rx='7.7347'
        transform='matrix(0.966176 0.257884 -0.259757 0.965674 38.6328 0)'
        fill='url(#paint4_linear_1_160)'
      />
      <rect
        width='15.4694'
        height='42.8265'
        rx='7.7347'
        transform='matrix(0.966176 0.257884 -0.259757 0.965674 88.7732 0)'
        fill='url(#paint5_linear_1_160)'
      />
      <rect
        width='15.4694'
        height='42.8265'
        rx='7.7347'
        transform='matrix(0.966176 0.257884 -0.259757 0.965674 138.914 0)'
        fill='url(#paint6_linear_1_160)'
      />
      <rect
        width='15.4694'
        height='42.8265'
        rx='7.7347'
        transform='matrix(0.966176 0.257884 -0.259757 0.965674 189.054 0)'
        fill='url(#paint7_linear_1_160)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1_160'
          x1='1.71882'
          y1='37.6873'
          x2='15.3912'
          y2='5.10635'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3981EE' />
          <stop offset='1' stopColor='#92BDFF' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1_160'
          x1='1.71882'
          y1='37.6873'
          x2='15.3912'
          y2='5.10635'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3981EE' />
          <stop offset='1' stopColor='#92BDFF' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1_160'
          x1='1.71882'
          y1='37.6873'
          x2='15.3912'
          y2='5.10635'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3981EE' />
          <stop offset='1' stopColor='#92BDFF' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_1_160'
          x1='1.71882'
          y1='37.6873'
          x2='15.3912'
          y2='5.10635'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3981EE' />
          <stop offset='1' stopColor='#92BDFF' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_1_160'
          x1='1.71882'
          y1='37.6873'
          x2='15.3912'
          y2='5.10635'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3981EE' />
          <stop offset='1' stopColor='#92BDFF' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_1_160'
          x1='1.71882'
          y1='37.6873'
          x2='15.3912'
          y2='5.10635'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3981EE' />
          <stop offset='1' stopColor='#92BDFF' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_1_160'
          x1='1.71882'
          y1='37.6873'
          x2='15.3912'
          y2='5.10635'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3981EE' />
          <stop offset='1' stopColor='#92BDFF' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_1_160'
          x1='1.71882'
          y1='37.6873'
          x2='15.3912'
          y2='5.10635'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3981EE' />
          <stop offset='1' stopColor='#92BDFF' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Humidity
