import React from 'react'

const EarthIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM6.99998 3.33783C8.47085 2.48698 10.1786 2 12 2C16.4776 2 20.2679 4.94289 21.5422 9H18.0002C17.4479 9 17.0002 9.44772 17.0002 10V11.953C17.0002 12.0155 17.006 12.0778 17.0177 12.1392L17.6035 15.231L16.2054 17.0622C16.0723 17.2365 16.0002 17.4497 16.0002 17.6691V21.1678C14.7752 21.7031 13.4223 22 12 22C11.6033 22 11.2119 21.9769 10.8272 21.932L10.1692 17.6745C10.1205 17.3592 9.92427 17.086 9.64099 16.9393L6.00018 15.0531L6.00018 11.8582L8.93873 10.8597L12.9692 10.9839C13.5333 11.0013 14 10.5487 14 9.98436V6C14 5.44772 13.5523 5 13 5H10.6919C10.3409 5 10.0156 5.18402 9.83481 5.48487L9.35349 6.28571H6.99998V3.33783ZM5.99998 3.99928C3.5711 5.8237 2 8.72837 2 12C2 16.7627 5.32952 20.7477 9.78792 21.7545L9.18098 17.8272L5.54017 15.941C5.20845 15.7691 5.00018 15.4267 5.00018 15.0531V11.8582C5.00018 11.4299 5.27293 11.0491 5.67846 10.9113L8.61701 9.91286C8.73041 9.87433 8.84982 9.85648 8.96953 9.86017L13 9.98436L13 6H10.6919L10.2106 6.80085C10.0298 7.10169 9.70449 7.28571 9.35349 7.28571H6.99998C6.4477 7.28571 5.99998 6.838 5.99998 6.28571V3.99928ZM17.0002 20.6621C19.9891 18.933 22 15.7013 22 12C22 11.3151 21.9311 10.6462 21.8 10H18.0002V11.953L18.5861 15.0449C18.6389 15.3239 18.5707 15.6122 18.3984 15.8379L17.0002 17.6691V20.6621Z'
        fill='white'
      />
    </svg>
  )
}

export default EarthIcon
