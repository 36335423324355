import React from 'react'

const HomeIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0002 1.354L23.3168 10.6131L22.6836 11.387L20.0002 9.19152V19.5C20.0002 20.8807 18.8809 22 17.5002 22H6.50021C5.1195 22 4.00021 20.8807 4.00021 19.5V9.19152L1.31683 11.387L0.683594 10.6131L12.0002 1.354ZM5.00021 8.37334V19.5C5.00021 20.3285 5.67179 21 6.50021 21H9.00021V14.5C9.00021 13.6716 9.67179 13 10.5002 13H13.5002C14.3286 13 15.0002 13.6716 15.0002 14.5V21H17.5002C18.3286 21 19.0002 20.3285 19.0002 19.5V8.37334L12.0002 2.64606L5.00021 8.37334ZM14.0002 21V14.5C14.0002 14.2239 13.7764 14 13.5002 14H10.5002C10.2241 14 10.0002 14.2239 10.0002 14.5V21H14.0002Z'
        fill='#339CFF'
      />
    </svg>
  )
}

export default HomeIcon
