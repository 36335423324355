import React from 'react'

const ClearDay = () => {
  return (
    <svg
      width='394'
      height='380'
      viewBox='0 0 394 380'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_i_1_52)'>
        <path
          d='M338.605 123.814C338.968 121.762 339.157 119.651 339.157 117.497C339.157 97.3401 322.606 81 302.19 81C287.012 81 273.971 90.0306 268.278 102.946C263.666 99.015 257.681 96.6414 251.14 96.6414C236.557 96.6414 224.736 108.443 224.736 123C224.736 123.811 224.772 124.613 224.844 125.405C217.83 128.797 213 135.914 213 144.145C213 155.663 222.458 165 234.124 165H333.876C345.542 165 355 155.663 355 144.145C355 134.232 347.995 125.934 338.605 123.814Z'
          fill='url(#paint0_linear_1_52)'
        />
      </g>
      <g filter='url(#filter1_f_1_52)'>
        <rect x='77' y='77' width='240' height='226' rx='94' fill='#FFEF9A' />
      </g>
      <g filter='url(#filter2_i_1_52)'>
        <path
          d='M303 190C303 249.094 255.542 297 197 297C138.458 297 91 249.094 91 190C91 130.906 138.458 83 197 83C255.542 83 303 130.906 303 190Z'
          fill='url(#paint1_linear_1_52)'
        />
      </g>
      <g filter='url(#filter3_i_1_52)'>
        <path
          d='M180.605 271.814C180.968 269.762 181.157 267.651 181.157 265.497C181.157 245.34 164.606 229 144.19 229C129.012 229 115.971 238.031 110.278 250.946C105.666 247.015 99.6807 244.641 93.1405 244.641C78.5574 244.641 66.7355 256.443 66.7355 271C66.7355 271.811 66.7722 272.613 66.8439 273.405C59.8298 276.797 55 283.914 55 292.145C55 303.663 64.4575 313 76.124 313H175.876C187.542 313 197 303.663 197 292.145C197 282.232 189.995 273.934 180.605 271.814Z'
          fill='url(#paint2_linear_1_52)'
        />
      </g>
      <defs>
        <filter
          id='filter0_i_1_52'
          x='213'
          y='81'
          width='142'
          height='94'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='11' />
          <feGaussianBlur stdDeviation='5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_1_52'
          />
        </filter>
        <filter
          id='filter1_f_1_52'
          x='0'
          y='0'
          width='394'
          height='380'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='38.5'
            result='effect1_foregroundBlur_1_52'
          />
        </filter>
        <filter
          id='filter2_i_1_52'
          x='91'
          y='83'
          width='212'
          height='219'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='5' />
          <feGaussianBlur stdDeviation='9' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.81 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_1_52'
          />
        </filter>
        <filter
          id='filter3_i_1_52'
          x='55'
          y='229'
          width='142'
          height='94'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='11' />
          <feGaussianBlur stdDeviation='5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_1_52'
          />
        </filter>
        <linearGradient
          id='paint0_linear_1_52'
          x1='222.032'
          y1='157.5'
          x2='364.923'
          y2='50.2665'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0.58' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1_52'
          x1='180.899'
          y1='248.241'
          x2='269.356'
          y2='94.5502'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF9900' />
          <stop offset='1' stopColor='#FFEE94' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1_52'
          x1='64.0318'
          y1='305.5'
          x2='206.923'
          y2='198.267'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0.58' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ClearDay
