import React from 'react'

const Cloud = () => {
  return (
    <svg
      width='285'
      height='185'
      viewBox='0 0 285 185'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_bd_1_129)'>
        <mask
          id='path-1-outside-1_1_129'
          maskUnits='userSpaceOnUse'
          x='15'
          y='11'
          width='255'
          height='155'
          fill='black'
        >
          <rect fill='white' x='15' y='11' width='255' height='155' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M197.882 82.0141C197.882 84.4233 197.758 86.8036 197.517 89.149C204.953 82.6871 214.664 78.7752 225.288 78.7752C248.68 78.7752 267.643 97.7383 267.643 121.13C267.643 144.523 248.68 163.486 225.288 163.486H60.6379C60.6256 163.486 60.6133 163.486 60.601 163.486C60.5887 163.486 60.5764 163.486 60.5641 163.486H60.3518V163.485C36.3863 163.351 17 143.882 17 119.885C17 95.9758 36.2441 76.5617 60.0878 76.2867C62.999 40.8508 92.6809 13 128.868 13C166.983 13 197.882 43.8987 197.882 82.0141Z'
          />
        </mask>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M197.882 82.0141C197.882 84.4233 197.758 86.8036 197.517 89.149C204.953 82.6871 214.664 78.7752 225.288 78.7752C248.68 78.7752 267.643 97.7383 267.643 121.13C267.643 144.523 248.68 163.486 225.288 163.486H60.6379C60.6256 163.486 60.6133 163.486 60.601 163.486C60.5887 163.486 60.5764 163.486 60.5641 163.486H60.3518V163.485C36.3863 163.351 17 143.882 17 119.885C17 95.9758 36.2441 76.5617 60.0878 76.2867C62.999 40.8508 92.6809 13 128.868 13C166.983 13 197.882 43.8987 197.882 82.0141Z'
          fill='url(#paint0_linear_1_129)'
          shapeRendering='crispEdges'
        />
        <path
          d='M197.517 89.149L195.528 88.9447L195.011 93.9771L198.829 90.6586L197.517 89.149ZM60.3518 163.486H58.3518V165.486H60.3518V163.486ZM60.3518 163.485H62.3518V161.496L60.363 161.485L60.3518 163.485ZM60.0878 76.2867L60.1109 78.2866L61.932 78.2655L62.0811 76.4504L60.0878 76.2867ZM199.507 89.3534C199.755 86.9401 199.882 84.4916 199.882 82.0141H195.882C195.882 84.3549 195.762 86.667 195.528 88.9447L199.507 89.3534ZM225.288 76.7752C214.163 76.7752 203.991 80.8733 196.206 87.6395L198.829 90.6586C205.915 84.5008 215.164 80.7752 225.288 80.7752V76.7752ZM269.643 121.13C269.643 96.6337 249.785 76.7752 225.288 76.7752V80.7752C247.576 80.7752 265.643 98.8429 265.643 121.13H269.643ZM225.288 165.486C249.785 165.486 269.643 145.627 269.643 121.13H265.643C265.643 143.418 247.576 161.486 225.288 161.486V165.486ZM225.288 161.486H60.6379V165.486H225.288V161.486ZM60.601 165.486C60.6079 165.486 60.6146 165.486 60.6207 165.486C60.6269 165.486 60.6324 165.486 60.6379 165.486V161.486C60.631 161.486 60.6242 161.486 60.6182 161.486C60.6119 161.486 60.6064 161.486 60.601 161.486V165.486ZM60.5641 165.486C60.5695 165.486 60.5751 165.486 60.5813 165.486C60.5873 165.486 60.5941 165.486 60.601 165.486V161.486C60.5956 161.486 60.59 161.486 60.5838 161.486C60.5778 161.486 60.571 161.486 60.5641 161.486V165.486ZM60.5641 161.486H60.3518V165.486H60.5641V161.486ZM62.3518 163.486V163.485H58.3518V163.486H62.3518ZM15 119.885C15 144.983 35.2756 165.345 60.3407 165.485L60.363 161.485C37.4971 161.357 19 142.781 19 119.885H15ZM60.0647 74.2868C35.1268 74.5744 15 94.879 15 119.885H19C19 97.0727 37.3614 78.5489 60.1109 78.2866L60.0647 74.2868ZM128.868 11C91.6312 11 61.0902 39.6581 58.0945 76.1229L62.0811 76.4504C64.9077 42.0436 93.7306 15 128.868 15V11ZM199.882 82.0141C199.882 42.7941 168.088 11 128.868 11V15C165.879 15 195.882 45.0033 195.882 82.0141H199.882Z'
          fill='url(#paint1_linear_1_129)'
          mask='url(#path-1-outside-1_1_129)'
        />
      </g>
      <defs>
        <filter
          id='filter0_bd_1_129'
          x='0'
          y='0'
          width='284.643'
          height='184.486'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='5' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_1_129'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='7.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_backgroundBlur_1_129'
            result='effect2_dropShadow_1_129'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_1_129'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_1_129'
          x1='94.9027'
          y1='12.3724'
          x2='155.193'
          y2='164.113'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='0.708333' stopColor='#9DDCFF' stopOpacity='0.69' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1_129'
          x1='134.193'
          y1='-39.1111'
          x2='217.515'
          y2='225.081'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='0.713542' stopColor='#85C6EC' stopOpacity='0.71' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Cloud
