import React from 'react'

const CoffeIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.75431 3.79082C5.71062 3.46467 5 2.49808 5 1.40462V1H6V1.40462C6 2.0607 6.42637 2.64065 7.05259 2.83634L8.24569 3.20918C9.28938 3.53533 10 4.50192 10 5.59538V6H9V5.59538C9 4.9393 8.57363 4.35935 7.94741 4.16366L6.75431 3.79082Z'
        fill='#339CFF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 16V16.5C18 17.8807 16.8807 19 15.5 19H6.5C5.11929 19 4 17.8807 4 16.5V9.5C4 8.67157 4.67157 8 5.5 8H18C20.2091 8 22 9.79086 22 12C22 14.2091 20.2091 16 18 16ZM21 12C21 10.3431 19.6569 9 18 9V15C19.6569 15 21 13.6569 21 12ZM5 9.5C5 9.22386 5.22386 9 5.5 9H17V16.5C17 17.3284 16.3284 18 15.5 18H6.5C5.67157 18 5 17.3284 5 16.5V9.5Z'
        fill='#339CFF'
      />
      <path d='M22 22H2V21H22V22Z' fill='#339CFF' />
      <path
        d='M10 1.40462C10 2.49808 10.7106 3.46467 11.7543 3.79082L12.9474 4.16366C13.5736 4.35935 14 4.9393 14 5.59538V6H15V5.59538C15 4.50192 14.2894 3.53533 13.2457 3.20918L12.0526 2.83634C11.4264 2.64065 11 2.0607 11 1.40462V1H10V1.40462Z'
        fill='#339CFF'
      />
    </svg>
  )
}

export default CoffeIcon
