import React from 'react'

const LogoRain = () => {
  return (
    <svg
      width='396'
      height='327'
      viewBox='0 0 396 327'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_di_1_57)'>
        <path
          d='M125.5 215C113.049 231.333 95.6169 264 125.5 264C155.383 264 137.951 231.333 125.5 215Z'
          fill='#00BCFF'
        />
      </g>
      <g filter='url(#filter1_di_1_57)'>
        <path
          d='M195.5 239C183.049 255.333 165.617 288 195.5 288C225.383 288 207.951 255.333 195.5 239Z'
          fill='#00BCFF'
        />
      </g>
      <g filter='url(#filter2_di_1_57)'>
        <path
          d='M260.5 215C248.049 231.333 230.617 264 260.5 264C290.383 264 272.951 231.333 260.5 215Z'
          fill='#00BCFF'
        />
      </g>
      <g filter='url(#filter3_f_1_57)'>
        <rect x='78' y='182' width='240' height='35' rx='17.5' fill='#00BCFF' />
      </g>
      <g filter='url(#filter4_b_1_57)'>
        <g filter='url(#filter5_i_1_57)'>
          <path
            d='M317.747 95.0084C318.549 90.4546 318.967 85.7701 318.967 80.9888C318.967 36.2599 282.369 0 237.223 0C203.661 0 174.823 20.0397 162.234 48.7007C152.035 39.9768 138.801 34.7095 124.339 34.7095C92.0918 34.7095 65.9504 60.8972 65.9504 93.2014C65.9504 95.0003 66.0315 96.7801 66.1902 98.5376C50.6799 106.065 40 121.858 40 140.124C40 165.683 60.9131 186.403 86.7108 186.403H307.289C333.087 186.403 354 165.683 354 140.124C354 118.126 338.51 99.7132 317.747 95.0084Z'
            fill='url(#paint0_linear_1_57)'
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_di_1_57'
          x='90'
          y='215'
          width='71'
          height='88'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='20' />
          <feGaussianBlur stdDeviation='9.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.283785 0 0 0 0 0.178889 0 0 0 0 0.933333 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_1_57'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_1_57'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='9' />
          <feGaussianBlur stdDeviation='1.5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.21 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_1_57'
          />
        </filter>
        <filter
          id='filter1_di_1_57'
          x='160'
          y='239'
          width='71'
          height='88'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='20' />
          <feGaussianBlur stdDeviation='9.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.283785 0 0 0 0 0.178889 0 0 0 0 0.933333 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_1_57'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_1_57'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='9' />
          <feGaussianBlur stdDeviation='1.5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.21 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_1_57'
          />
        </filter>
        <filter
          id='filter2_di_1_57'
          x='225'
          y='215'
          width='71'
          height='88'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='20' />
          <feGaussianBlur stdDeviation='9.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.283785 0 0 0 0 0.178889 0 0 0 0 0.933333 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_1_57'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_1_57'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='9' />
          <feGaussianBlur stdDeviation='1.5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.21 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_1_57'
          />
        </filter>
        <filter
          id='filter3_f_1_57'
          x='0'
          y='104'
          width='396'
          height='191'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='39'
            result='effect1_foregroundBlur_1_57'
          />
        </filter>
        <filter
          id='filter4_b_1_57'
          x='13'
          y='-27'
          width='368'
          height='240.403'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='13.5' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_1_57'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_1_57'
            result='shape'
          />
        </filter>
        <filter
          id='filter5_i_1_57'
          x='40'
          y='0'
          width='314'
          height='196.403'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='11' />
          <feGaussianBlur stdDeviation='5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_1_57'
          />
        </filter>
        <linearGradient
          id='paint0_linear_1_57'
          x1='59.9717'
          y1='169.76'
          x2='376.746'
          y2='-67.1272'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0.58' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LogoRain
