const MOCK_CITIES = [
  'Campinas',
  'Lisbon',
  'Tokyo',
  'Paris',
  'Miami',
  'Hong Kong',
  'London',
  'New Orleans',
  'Sydney',
  'Orlando',
  'Bangkok',
]

export default MOCK_CITIES
