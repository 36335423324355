import React from 'react'

const Moonrise = () => {
  return (
    <svg
      width='255'
      height='186'
      viewBox='0 0 255 186'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='path-1-outside-1_2_1170'
        maskUnits='userSpaceOnUse'
        x='76'
        y='7'
        width='160'
        height='179'
        fill='black'
      >
        <rect fill='white' x='76' y='7' width='160' height='179' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M231 153.125C228.947 153.271 226.874 153.345 224.785 153.345C176.875 153.345 138.037 114.345 138.037 66.2353C138.037 44.8009 145.746 25.1746 158.532 10C113.524 13.1997 78 50.879 78 96.8899C78 144.999 116.838 184 164.748 184C191.312 184 215.087 172.01 231 153.125Z'
        />
      </mask>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M231 153.125C228.947 153.271 226.874 153.345 224.785 153.345C176.875 153.345 138.037 114.345 138.037 66.2353C138.037 44.8009 145.746 25.1746 158.532 10C113.524 13.1997 78 50.879 78 96.8899C78 144.999 116.838 184 164.748 184C191.312 184 215.087 172.01 231 153.125Z'
        fill='url(#paint0_linear_2_1170)'
      />
      <path
        d='M231 153.125L232.529 154.414L235.579 150.795L230.858 151.13L231 153.125ZM158.532 10L160.062 11.2887L163.112 7.66941L158.391 8.00503L158.532 10ZM230.858 151.13C228.852 151.273 226.827 151.345 224.785 151.345V155.345C226.922 155.345 229.042 155.27 231.142 155.12L230.858 151.13ZM224.785 151.345C177.987 151.345 140.037 113.248 140.037 66.2353H136.037C136.037 115.442 175.763 155.345 224.785 155.345V151.345ZM140.037 66.2353C140.037 45.289 147.569 26.1155 160.062 11.2887L157.003 8.71127C143.924 24.2337 136.037 44.3127 136.037 66.2353H140.037ZM80 96.8899C80 51.929 114.712 15.1203 158.674 11.995L158.391 8.00503C112.336 11.2792 76 49.829 76 96.8899H80ZM164.748 182C117.951 182 80 143.903 80 96.8899H76C76 146.096 115.726 186 164.748 186V182ZM229.471 151.837C213.921 170.291 190.696 182 164.748 182V186C191.928 186 216.254 173.729 232.529 154.414L229.471 151.837Z'
        fill='url(#paint1_linear_2_1170)'
        mask='url(#path-1-outside-1_2_1170)'
      />
      <path
        d='M38.7732 4.04102C36.8263 -0.0136695 31.1737 -0.0136774 29.2268 4.04102L22.6831 17.6699C22.1939 18.6887 21.2558 19.3816 20.186 19.5414L5.55368 21.7269C1.16309 22.3827 -0.514471 27.8708 2.60077 30.9921L13.1888 41.6007C13.9749 42.3883 14.3399 43.5315 14.1519 44.6578L11.6525 59.6374C10.9212 64.02 15.4233 67.4976 19.3787 65.3601L32.4663 58.2877C33.4274 57.7683 34.5726 57.7683 35.5337 58.2877L48.6212 65.3601C52.5767 67.4976 57.0788 64.02 56.3475 59.6374L53.8481 44.6578C53.6601 43.5315 54.0251 42.3883 54.8112 41.6007L65.3992 30.9921C68.5145 27.8708 66.8369 22.3827 62.4463 21.7269L47.814 19.5414C46.7442 19.3816 45.8061 18.6887 45.3169 17.6699L38.7732 4.04102Z'
        fill='url(#paint2_linear_2_1170)'
        stroke='url(#paint3_linear_2_1170)'
        strokeWidth='2'
      />
      <path
        d='M234.437 73.1262C233.03 70.2913 228.97 70.2913 227.563 73.1262L223.269 81.7794C223.003 82.3151 222.488 82.689 221.888 82.7756L212.286 84.1633C209.148 84.6167 207.879 88.4635 210.163 90.6772L217.111 97.4128C217.542 97.8309 217.738 98.4315 217.637 99.0189L215.996 108.53C215.456 111.66 218.755 114.021 221.556 112.556L230.145 108.066C230.68 107.786 231.32 107.786 231.855 108.066L240.444 112.556C243.245 114.021 246.544 111.66 246.004 108.53L244.363 99.0189C244.262 98.4315 244.458 97.8309 244.889 97.4128L251.837 90.6772C254.121 88.4635 252.852 84.6167 249.714 84.1633L240.112 82.7756C239.512 82.689 238.997 82.3151 238.731 81.7794L234.437 73.1262Z'
        fill='url(#paint4_linear_2_1170)'
        stroke='url(#paint5_linear_2_1170)'
        strokeWidth='2'
      />
      <defs>
        <linearGradient
          id='paint0_linear_2_1170'
          x1='170.599'
          y1='65.469'
          x2='133.687'
          y2='183.913'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFEFB8' />
          <stop offset='1' stopColor='#FFD600' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_2_1170'
          x1='70.6226'
          y1='-16.532'
          x2='206.033'
          y2='213.907'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFF2AF' />
          <stop offset='1' stopColor='#CEBA00' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_2_1170'
          x1='42.7273'
          y1='21.5'
          x2='16.8505'
          y2='64.3051'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFF59F' />
          <stop offset='1' stopColor='#EDD500' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_2_1170'
          x1='10'
          y1='-2.5'
          x2='57.3202'
          y2='86.3122'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFF6A6' />
          <stop offset='1' stopColor='#D1BC00' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_2_1170'
          x1='236.727'
          y1='84.381'
          x2='220.555'
          y2='112.032'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFF59F' />
          <stop offset='1' stopColor='#EDD500' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_2_1170'
          x1='215.25'
          y1='69.1429'
          x2='244.735'
          y2='126.34'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFF6A6' />
          <stop offset='1' stopColor='#D1BC00' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Moonrise
