import React from 'react'

const CloudyNight = () => {
  return (
    <svg
      width='307'
      height='324'
      viewBox='0 0 307 324'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_i_1_35)'>
        <path
          d='M219.065 66.4403C219.484 64.0706 219.702 61.6329 219.702 59.1448C219.702 35.8689 200.588 17 177.008 17C159.479 17 144.417 27.4282 137.842 42.3428C132.515 37.803 125.603 35.0621 118.05 35.0621C101.207 35.0621 87.5537 48.6896 87.5537 65.5C87.5537 66.4361 87.5961 67.3623 87.6789 68.2768C79.578 72.1937 74 80.4125 74 89.9172C74 103.218 84.9228 114 98.3967 114H213.603C227.077 114 238 103.218 238 89.9172C238 78.4703 229.909 68.8886 219.065 66.4403Z'
          fill='url(#paint0_linear_1_35)'
        />
      </g>
      <g filter='url(#filter1_f_1_35)'>
        <path
          d='M153.855 53.1342C128.788 87.1678 124.442 133.614 146.554 171.914C168.666 210.213 211.063 229.672 253.07 224.981C244.138 237.108 232.576 247.659 218.642 255.704C165.604 286.325 98.2955 269.038 68.3042 217.091C38.3128 165.145 56.9957 98.2101 110.034 67.5887C123.967 59.544 138.886 54.8059 153.855 53.1342Z'
          fill='#FFEE94'
        />
      </g>
      <g filter='url(#filter2_i_1_35)'>
        <path
          d='M151.517 67.4758C128.636 98.5427 124.668 140.941 144.853 175.902C165.038 210.863 203.739 228.626 242.085 224.343C233.931 235.413 223.377 245.045 210.657 252.388C162.243 280.34 100.801 264.56 73.4241 217.141C46.047 169.723 63.1014 108.623 111.516 80.6703C124.235 73.3268 137.854 69.0018 151.517 67.4758Z'
          fill='url(#paint1_linear_1_35)'
        />
      </g>
      <g filter='url(#filter3_b_1_35)'>
        <g filter='url(#filter4_i_1_35)'>
          <path
            d='M254.447 252.673C254.968 249.717 255.24 246.676 255.24 243.572C255.24 214.537 231.463 191 202.132 191C180.328 191 161.592 204.008 153.413 222.613C146.787 216.95 138.189 213.531 128.793 213.531C107.843 213.531 90.8595 230.53 90.8595 251.5C90.8595 252.668 90.9122 253.823 91.0152 254.964C80.9385 259.85 74 270.102 74 281.959C74 298.55 87.5869 312 104.347 312H247.653C264.413 312 278 298.55 278 281.959C278 267.679 267.936 255.727 254.447 252.673Z'
            fill='url(#paint2_linear_1_35)'
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_i_1_35'
          x='74'
          y='17'
          width='164'
          height='107'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='11' />
          <feGaussianBlur stdDeviation='5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_1_35'
          />
        </filter>
        <filter
          id='filter1_f_1_35'
          x='0.994377'
          y='0.134216'
          width='305.076'
          height='323.713'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='26.5'
            result='effect1_foregroundBlur_1_35'
          />
        </filter>
        <filter
          id='filter2_i_1_35'
          x='60.3617'
          y='67.4758'
          width='181.723'
          height='202.735'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='4' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.55 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_1_35'
          />
        </filter>
        <filter
          id='filter3_b_1_35'
          x='47'
          y='164'
          width='258'
          height='175'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='13.5' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_1_35'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_1_35'
            result='shape'
          />
        </filter>
        <filter
          id='filter4_i_1_35'
          x='74'
          y='191'
          width='204'
          height='131'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='11' />
          <feGaussianBlur stdDeviation='5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_1_35'
          />
        </filter>
        <linearGradient
          id='paint0_linear_1_35'
          x1='84.4311'
          y1='105.339'
          x2='249.444'
          y2='-18.5129'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0.58' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1_35'
          x1='156.5'
          y1='51'
          x2='120.175'
          y2='270.951'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFEE94' />
          <stop offset='1' stopColor='#FF9900' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1_35'
          x1='86.9753'
          y1='301.196'
          x2='292.652'
          y2='147.259'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0.58' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default CloudyNight
