import React from 'react'

const Wind = () => {
  return (
    <svg
      width='197'
      height='162'
      viewBox='0 0 197 162'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M139.06 6.5C139.06 2.91015 141.97 0 145.56 0C161.835 0 175 13.2235 175 29.5C175 45.7765 161.835 59 145.56 59H52C48.4101 59 45.5 56.0899 45.5 52.5C45.5 48.9101 48.4101 46 52 46H145.56C154.624 46 162 38.6286 162 29.5C162 20.3714 154.624 13 145.56 13C141.97 13 139.06 10.0899 139.06 6.5ZM0 81.5C0 77.9101 2.91015 75 6.5 75L134.5 75C138.09 75 141 77.9102 141 81.5C141 85.0899 138.09 88 134.5 88L6.5 88C2.91015 88 0 85.0899 0 81.5ZM149 81.5C149 77.9101 151.91 75 155.5 75H183.5C187.09 75 190 77.9101 190 81.5C190 85.0899 187.09 88 183.5 88H155.5C151.91 88 149 85.0899 149 81.5ZM10 109.5C10 105.91 12.9101 103 16.5 103H44.5C48.0899 103 51 105.91 51 109.5C51 113.09 48.0899 116 44.5 116H16.5C12.9101 116 10 113.09 10 109.5ZM60 109.5C60 105.91 62.9101 103 66.5 103H167.584C183.852 103 197 116.23 197 132.5C197 148.77 183.852 162 167.584 162C163.994 162 161.084 159.09 161.084 155.5C161.084 151.91 163.994 149 167.584 149C176.628 149 184 141.635 184 132.5C184 123.365 176.628 116 167.584 116H66.5C62.9101 116 60 113.09 60 109.5Z'
        fill='url(#paint0_linear_1_140)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1_140'
          x1='171.282'
          y1='13.365'
          x2='56.6891'
          y2='149.317'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D6E9FF' />
          <stop offset='1' stopColor='#2898FF' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Wind
