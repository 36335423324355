import React from 'react'

const LogoutIcon = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.5 11V1H9.5V11H8.5ZM4.05026 4.05025C3.07129 5.02922 2.4046 6.2765 2.13451 7.63437C1.86441 8.99223 2.00303 10.3997 2.53285 11.6788C3.06266 12.9579 3.95987 14.0511 5.11101 14.8203C6.26216 15.5895 7.61553 16 9 16C10.3845 16 11.7379 15.5895 12.889 14.8203C14.0401 14.0511 14.9373 12.9579 15.4672 11.6788C15.997 10.3997 16.1356 8.99223 15.8655 7.63437C15.5954 6.2765 14.9287 5.02922 13.9497 4.05025L14.6569 3.34314C15.7757 4.46197 16.5376 5.88743 16.8463 7.43928C17.155 8.99113 16.9965 10.5997 16.391 12.0615C15.7855 13.5233 14.7602 14.7727 13.4446 15.6518C12.129 16.5308 10.5823 17 9 17C7.41775 17 5.87103 16.5308 4.55544 15.6518C3.23985 14.7727 2.21447 13.5233 1.60897 12.0615C1.00347 10.5997 0.84504 8.99112 1.15372 7.43928C1.4624 5.88743 2.22433 4.46196 3.34315 3.34314L4.05026 4.05025Z'
        fill='#339CFF'
      />
    </svg>
  )
}

export default LogoutIcon
