import React from 'react'

const LogoStorm = () => {
  return (
    <svg
      width='411'
      height='326'
      viewBox='0 0 411 326'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_b_1_24)'>
        <g filter='url(#filter1_i_1_24)'>
          <path
            d='M326.747 95.0084C327.549 90.4546 327.967 85.7701 327.967 80.9888C327.967 36.2599 291.369 0 246.223 0C212.661 0 183.823 20.0397 171.234 48.7007C161.035 39.9768 147.801 34.7095 133.339 34.7095C101.092 34.7095 74.9504 60.8972 74.9504 93.2014C74.9504 95.0003 75.0315 96.7801 75.1902 98.5376C59.6799 106.065 49 121.858 49 140.124C49 165.683 69.9131 186.403 95.7108 186.403H316.289C342.087 186.403 363 165.683 363 140.124C363 118.126 347.51 99.7132 326.747 95.0084Z'
            fill='url(#paint0_linear_1_24)'
          />
        </g>
      </g>
      <g filter='url(#filter2_i_1_24)'>
        <path
          d='M233.775 186.5H183.75L162 244.986H201.15L183.025 318L249 222.211H220L233.775 186.5Z'
          fill='url(#paint1_linear_1_24)'
        />
      </g>
      <g filter='url(#filter3_f_1_24)'>
        <rect x='77' y='153' width='257' height='50' rx='25' fill='#FFED8D' />
      </g>
      <g filter='url(#filter4_di_1_24)'>
        <path
          d='M268.151 292.575C260.385 296.024 257.354 298.938 254.575 306.151C252.363 299.161 249.642 296.043 241 292.575C249.89 289.679 252.303 286.326 254.575 279C257.368 285.752 259.092 289.437 268.151 292.575Z'
          fill='url(#paint2_linear_1_24)'
        />
      </g>
      <g filter='url(#filter5_i_1_24)'>
        <path
          d='M142.151 216.575C134.385 220.024 131.354 222.938 128.575 230.151C126.363 223.161 123.642 220.043 115 216.575C123.89 213.679 126.303 210.326 128.575 203C131.368 209.752 133.092 213.437 142.151 216.575Z'
          fill='url(#paint3_linear_1_24)'
        />
      </g>
      <defs>
        <filter
          id='filter0_b_1_24'
          x='22'
          y='-27'
          width='368'
          height='240.403'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='13.5' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_1_24'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_1_24'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_i_1_24'
          x='49'
          y='0'
          width='314'
          height='196.403'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='11' />
          <feGaussianBlur stdDeviation='5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_1_24'
          />
        </filter>
        <filter
          id='filter2_i_1_24'
          x='160'
          y='186.5'
          width='89'
          height='131.5'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-2' />
          <feGaussianBlur stdDeviation='4.5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_1_24'
          />
        </filter>
        <filter
          id='filter3_f_1_24'
          x='0'
          y='76'
          width='411'
          height='204'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='38.5'
            result='effect1_foregroundBlur_1_24'
          />
        </filter>
        <filter
          id='filter4_di_1_24'
          x='232.233'
          y='279'
          width='44.6855'
          height='46.9181'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='11' />
          <feGaussianBlur stdDeviation='4.38372' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.285052 0 0 0 0 0.179688 0 0 0 0 0.9375 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_1_24'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_1_24'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.44 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_1_24'
          />
        </filter>
        <filter
          id='filter5_i_1_24'
          x='115'
          y='203'
          width='27.1506'
          height='31.1507'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.44 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_1_24'
          />
        </filter>
        <linearGradient
          id='paint0_linear_1_24'
          x1='68.9717'
          y1='169.76'
          x2='385.746'
          y2='-67.1272'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0.58' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1_24'
          x1='194.747'
          y1='305.333'
          x2='279.616'
          y2='188.178'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF9900' />
          <stop offset='1' stopColor='#FFEE94' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_1_24'
          x1='250.648'
          y1='307.423'
          x2='263.425'
          y2='283.433'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF9900' />
          <stop offset='1' stopColor='#FFEE94' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_1_24'
          x1='124.648'
          y1='231.423'
          x2='137.425'
          y2='207.433'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF9900' />
          <stop offset='1' stopColor='#FFEE94' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LogoStorm
