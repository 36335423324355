const MOCK_WEATHERS = [
  'Clear',
  'Thunderstorm',
  'Drizzle',
  'Rain',
  'Snow',
  'Clouds',
]

export default MOCK_WEATHERS
